import burger from '@/assets/icons/burgerMenu.svg';
import closeIcon from '@/assets/icons/closeMenu.svg';
import LoginButton from '@/components/LoginButton/LoginButton';
import { Logo } from '@/components/Logo/Logo';
import { footerLinks, socialLinks } from '@/configs/urls';
import { useAuthContext } from '@/hooks/use-auth-context';
import { Button, Link, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import Menu from '../Menu/Menu';
import styles from './Header.module.scss';
import { useSoundContext } from 'sounds';
import { useAtomValue, useSetAtom } from 'jotai';
import { missionCountAtom } from 'atoms/mission-count-atom';
import { MediaLink } from '@/components/MediaLink/MediaLink';
import { userOnlineAtom } from 'atoms/user-online-atom';
import onlineIcon from '@/assets/icons/menu/onlineIcon.svg';
import { AccountBlock } from '@/components/Authorized/AccountBlock/AccountBlock';
import { PromoBlock } from '@/components/Authorized/PromoBlock/PromoBlock';
import { useRouter } from 'next/router';
import { useGetMissionsCompletionQuery } from '@/generated/missions.generated';

const DynamicSurvey = dynamic(() => import('./Survey/Survey'), {
  ssr: false,
});
export const Header = ({
  handleOpenModalPromo,
}: {
  handleOpenModalPromo: () => void;
}) => {
  const { isAuthorized, accessToken } = useAuthContext();
  const [open, setOpen] = useState(false);
  const userCount = useAtomValue(userOnlineAtom);
  const router = useRouter();
  const menuRef = useRef<any>(null);
  const theme = useTheme();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (
        menuRef.current &&
        !event.target.closest('.mobileMenuWrapper') &&
        !menuRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const setMissionCountAtom = useSetAtom(missionCountAtom);
  const storeURL = router.pathname.includes('/store');

  useGetMissionsCompletionQuery({
    context: { token: accessToken },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const res = data.GetBasicMissionsData!.one_time_missions;
      setMissionCountAtom({
        all: res.missions_full_count,
        completed: res.missions_complete_count,
        isCaseAvailable: res.bonus_case_available === 1,
      });
    },
  });

  const showMobileMenu = useMediaQuery(theme.breakpoints.down(1101));
  const showMobileLogo = useMediaQuery(theme.breakpoints.down(481));

  const { playSound } = useSoundContext();

  const handleMenu = () => {
    setOpen((prevState) => !prevState);
    playSound('click_general');
  };
  useEffect(() => {
    setOpen(false);
  }, [showMobileMenu]);
  const mobileLink = useMediaQuery(theme.breakpoints.down(1305));
  const mobileCoinUser = useMediaQuery(theme.breakpoints.down(900));
  const buttonPartnershipConLink = useMediaQuery(theme.breakpoints.down(1024));
  const mobilePromo = useMediaQuery(theme.breakpoints.down(1150));

  return (
    <header
      ref={menuRef}
      className={styles.headerWrapper}
      style={{ backgroundColor: `${storeURL ? '#0b0a1a' : '#0c1027'}` }}
    >
      <DynamicSurvey />
      <div className={styles.container}>
        <div
          className={styles.blockLeft}
          onClick={() => playSound('click_general')}
        >
          <Logo isAdaptive isMobile={showMobileLogo} />
          {!mobileCoinUser && (
            <div className={styles.countContainer}>
              <div className={styles.countUser}>
                <div className={styles.imageContainer}>
                  <div>
                    <Image src={onlineIcon} alt="Online Icon" />
                  </div>
                </div>
                {userCount.toLocaleString('ru-Ru')}
              </div>

              <div className={styles.countTxt}>Online</div>
            </div>
          )}
          <div className={styles.socLinkAndButtonCon}>
            {!mobileLink && (
              <div className={styles.mediaLinks}>
                {socialLinks.map((item) => {
                  return (
                    <MediaLink
                      icon={item.icon}
                      href={item.href}
                      key={item.href}
                      name={item.name}
                    />
                  );
                })}
              </div>
            )}
            {!buttonPartnershipConLink && (
              <div className={styles.buttonPatrnershipCon}>
                {footerLinks
                  .filter((item) => item.title === 'Партнерка')
                  .map((item) => {
                    return (
                      <Link href={item.href} key={item.title}>
                        <Button
                          color="info"
                          className={styles.buttonPatrnership}
                          variant="outlined"
                        >
                          <Image src={item.icon} alt="Heart" />
                          <p>{item.title}</p>
                        </Button>
                      </Link>
                    );
                  })}
              </div>
            )}
          </div>
        </div>

        <div className={styles.blockRight}>
          {!mobilePromo && (
            <div className={styles.promoCon}>
              <PromoBlock handleOpenModalPromo={handleOpenModalPromo} />
            </div>
          )}
          <div className={styles.authWrapper}>
            {isAuthorized ? <AccountBlock /> : <LoginButton from="header" />}
            <div
              className={styles.mobileMenuWrapper}
              ref={menuRef}
              onClick={handleMenu}
            >
              <Image
                src={open ? closeIcon : burger}
                alt="menu"
                style={{ fill: 'red' }}
              />
            </div>
          </div>
        </div>
      </div>
      <Menu
        open={open}
        close={() => setOpen(false)}
        handleOpenModalPromo={handleOpenModalPromo}
      />
    </header>
  );
};
