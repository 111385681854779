import Image from 'next/image';
import styles from './PromoBlock.module.scss';
import ArrowRightIcon from '@/assets/icons/pay/ArrowRightIcon';
import OvalButton from '@/components/styled/CustomButtons/OvalButton/OvalButton';
import promo from '@/assets/icons/pay/promo.svg';
import { theme } from '@/styles/theme';
import { useMediaQuery } from '@mui/material';

export const PromoBlock = ({
  handleOpenModalPromo,
}: {
  handleOpenModalPromo: () => void;
}) => {
  const mobile = useMediaQuery(theme.breakpoints.down(856));

  return (
    <div
      className={styles.promoCon}
      onClick={() => {
        handleOpenModalPromo();
      }}
    >
      <div className={styles.promoImg}>
        <Image src={promo} alt="Promocode" />
      </div>
      <p className={styles.promoTxt}>
        У меня есть <span style={{ color: '#D3F85A' }}>ПРОМОКОД</span>
      </p>
      {!mobile && (
        <OvalButton type="submit" small id="arrow" icon={<ArrowRightIcon />} />
      )}
    </div>
  );
};
