import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMissionsCompletionQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetMissionsCompletionQuery = {
  __typename?: 'query_root';
  GetBasicMissionsData?: {
    __typename?: 'BasicMissionsResponse';
    one_time_missions: {
      __typename?: 'BasicOneTimeMissionsList';
      missions_full_count: number;
      missions_complete_count: number;
      bonus_case_available: number;
    };
  } | null;
};

export type GetOneTimeMissionsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetOneTimeMissionsQuery = {
  __typename?: 'query_root';
  Missions__OnetimePageData?: {
    __typename?: 'MissionsOneTimeResponse';
    success: boolean;
    error?: string | null;
    bonus_case_available: number;
    missions_complete_count: number;
    missions_full_count: number;
    missions?: Array<{
      __typename?: 'MissionOneTimeResponse';
      mission_id: string;
      mission_title: string;
      mission_description: string;
      mission_image?: string | null;
      mission_reward: string;
      status: number;
      redirect_type: number;
      redirect_link: string;
      is_disabled: boolean;
    } | null> | null;
    bonus_case_data?: {
      __typename?: 'BonusLootboxContent';
      items?: Array<{
        __typename?: 'BonusLootboxItem';
        item_hash_name: string;
        item_id: string;
        item_image_link: string;
        item_image_link_case?: string | null;
        item_image_link_strip?: string | null;
        item_quality: string;
        item_rarity: string;
        localized_name?: string | null;
      } | null> | null;
      lootbox?: {
        __typename?: 'BaseBonusLootbox';
        lootbox_image_link: string;
        lootbox_name: string;
        lootbox_slug: string;
      } | null;
    } | null;
  } | null;
};

export type GetPermanentMissionsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetPermanentMissionsQuery = {
  __typename?: 'query_root';
  GetMissionsData?: {
    __typename?: 'MissionsResponse';
    permanent_missions: {
      __typename?: 'PermanentMissionsList';
      missions_full_count: number;
      missions: Array<{
        __typename?: 'PermanentMission';
        mission_availability: number;
        mission_current_progress: number;
        mission_description: string;
        mission_full_progress: number;
        mission_id: string;
        mission_reward: number;
        mission_title: string;
        mission_image?: string | null;
        mission_timer?: {
          __typename?: 'PermanentMissionTimer';
          launch: boolean;
          timer_value: number;
          max_value: number;
        } | null;
      }>;
    };
  } | null;
};

export type OneTimeMissionProgressMutationVariables = Types.Exact<{
  mission_id?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;

export type OneTimeMissionProgressMutation = {
  __typename?: 'mutation_root';
  Missions__Onetime: {
    __typename?: 'OnetimeMissionResponse';
    success?: boolean | null;
    error?: string | null;
    message?: string | null;
    missions_complete_count: number;
    bonus_case_available: number;
    statuses?: Array<{
      __typename?: 'MissionStatusResponse';
      mission_id: string;
      status?: number | null;
      is_disabled?: boolean | null;
    } | null> | null;
  };
};

export type PermanentMissionProgressMutationVariables = Types.Exact<{
  mission_id?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;

export type PermanentMissionProgressMutation = {
  __typename?: 'mutation_root';
  ProcessUserPermMissionProgress?: {
    __typename?: 'ProcMissionProgressResponse';
    success: boolean;
    error?: string | null;
    message?: string | null;
    redirect_type: number;
    redirect_url?: string | null;
    completed: boolean;
    mark_as_done: boolean;
    current_progress: number;
  } | null;
};

export const GetMissionsCompletionDocument = gql`
  query GetMissionsCompletion {
    GetBasicMissionsData {
      one_time_missions {
        missions_full_count
        missions_complete_count
        bonus_case_available
      }
    }
  }
`;

/**
 * __useGetMissionsCompletionQuery__
 *
 * To run a query within a React component, call `useGetMissionsCompletionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionsCompletionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionsCompletionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMissionsCompletionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >(GetMissionsCompletionDocument, options);
}
export function useGetMissionsCompletionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMissionsCompletionQuery,
    GetMissionsCompletionQueryVariables
  >(GetMissionsCompletionDocument, options);
}
export type GetMissionsCompletionQueryHookResult = ReturnType<
  typeof useGetMissionsCompletionQuery
>;
export type GetMissionsCompletionLazyQueryHookResult = ReturnType<
  typeof useGetMissionsCompletionLazyQuery
>;
export type GetMissionsCompletionQueryResult = Apollo.QueryResult<
  GetMissionsCompletionQuery,
  GetMissionsCompletionQueryVariables
>;
export const GetOneTimeMissionsDocument = gql`
  query GetOneTimeMissions {
    Missions__OnetimePageData {
      success
      error
      bonus_case_available
      missions_complete_count
      missions_full_count
      missions {
        mission_id
        mission_title
        mission_description
        mission_image
        mission_reward
        status
        redirect_type
        redirect_link
        is_disabled
      }
      bonus_case_data {
        items {
          item_hash_name
          item_id
          item_image_link
          item_image_link_case
          item_image_link_strip
          item_quality
          item_rarity
          localized_name
        }
        lootbox {
          lootbox_image_link
          lootbox_name
          lootbox_slug
        }
      }
    }
  }
`;

/**
 * __useGetOneTimeMissionsQuery__
 *
 * To run a query within a React component, call `useGetOneTimeMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTimeMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneTimeMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOneTimeMissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOneTimeMissionsQuery,
    GetOneTimeMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOneTimeMissionsQuery,
    GetOneTimeMissionsQueryVariables
  >(GetOneTimeMissionsDocument, options);
}
export function useGetOneTimeMissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneTimeMissionsQuery,
    GetOneTimeMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneTimeMissionsQuery,
    GetOneTimeMissionsQueryVariables
  >(GetOneTimeMissionsDocument, options);
}
export type GetOneTimeMissionsQueryHookResult = ReturnType<
  typeof useGetOneTimeMissionsQuery
>;
export type GetOneTimeMissionsLazyQueryHookResult = ReturnType<
  typeof useGetOneTimeMissionsLazyQuery
>;
export type GetOneTimeMissionsQueryResult = Apollo.QueryResult<
  GetOneTimeMissionsQuery,
  GetOneTimeMissionsQueryVariables
>;
export const GetPermanentMissionsDocument = gql`
  query GetPermanentMissions {
    GetMissionsData {
      permanent_missions {
        missions_full_count
        missions {
          mission_availability
          mission_current_progress
          mission_description
          mission_full_progress
          mission_id
          mission_reward
          mission_title
          mission_image
          mission_timer {
            launch
            timer_value
            max_value
          }
        }
      }
    }
  }
`;

/**
 * __useGetPermanentMissionsQuery__
 *
 * To run a query within a React component, call `useGetPermanentMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermanentMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermanentMissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermanentMissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPermanentMissionsQuery,
    GetPermanentMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPermanentMissionsQuery,
    GetPermanentMissionsQueryVariables
  >(GetPermanentMissionsDocument, options);
}
export function useGetPermanentMissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermanentMissionsQuery,
    GetPermanentMissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPermanentMissionsQuery,
    GetPermanentMissionsQueryVariables
  >(GetPermanentMissionsDocument, options);
}
export type GetPermanentMissionsQueryHookResult = ReturnType<
  typeof useGetPermanentMissionsQuery
>;
export type GetPermanentMissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermanentMissionsLazyQuery
>;
export type GetPermanentMissionsQueryResult = Apollo.QueryResult<
  GetPermanentMissionsQuery,
  GetPermanentMissionsQueryVariables
>;
export const OneTimeMissionProgressDocument = gql`
  mutation OneTimeMissionProgress($mission_id: uuid = "") {
    Missions__Onetime(mission_id: $mission_id) {
      success
      error
      message
      missions_complete_count
      bonus_case_available
      statuses {
        mission_id
        status
        is_disabled
      }
    }
  }
`;
export type OneTimeMissionProgressMutationFn = Apollo.MutationFunction<
  OneTimeMissionProgressMutation,
  OneTimeMissionProgressMutationVariables
>;

/**
 * __useOneTimeMissionProgressMutation__
 *
 * To run a mutation, you first call `useOneTimeMissionProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOneTimeMissionProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [oneTimeMissionProgressMutation, { data, loading, error }] = useOneTimeMissionProgressMutation({
 *   variables: {
 *      mission_id: // value for 'mission_id'
 *   },
 * });
 */
export function useOneTimeMissionProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OneTimeMissionProgressMutation,
    OneTimeMissionProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    OneTimeMissionProgressMutation,
    OneTimeMissionProgressMutationVariables
  >(OneTimeMissionProgressDocument, options);
}
export type OneTimeMissionProgressMutationHookResult = ReturnType<
  typeof useOneTimeMissionProgressMutation
>;
export type OneTimeMissionProgressMutationResult =
  Apollo.MutationResult<OneTimeMissionProgressMutation>;
export type OneTimeMissionProgressMutationOptions = Apollo.BaseMutationOptions<
  OneTimeMissionProgressMutation,
  OneTimeMissionProgressMutationVariables
>;
export const PermanentMissionProgressDocument = gql`
  mutation PermanentMissionProgress($mission_id: uuid = "") {
    ProcessUserPermMissionProgress(mission_id: $mission_id) {
      success
      error
      message
      redirect_type
      redirect_url
      completed
      mark_as_done
      current_progress
    }
  }
`;
export type PermanentMissionProgressMutationFn = Apollo.MutationFunction<
  PermanentMissionProgressMutation,
  PermanentMissionProgressMutationVariables
>;

/**
 * __usePermanentMissionProgressMutation__
 *
 * To run a mutation, you first call `usePermanentMissionProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePermanentMissionProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [permanentMissionProgressMutation, { data, loading, error }] = usePermanentMissionProgressMutation({
 *   variables: {
 *      mission_id: // value for 'mission_id'
 *   },
 * });
 */
export function usePermanentMissionProgressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PermanentMissionProgressMutation,
    PermanentMissionProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PermanentMissionProgressMutation,
    PermanentMissionProgressMutationVariables
  >(PermanentMissionProgressDocument, options);
}
export type PermanentMissionProgressMutationHookResult = ReturnType<
  typeof usePermanentMissionProgressMutation
>;
export type PermanentMissionProgressMutationResult =
  Apollo.MutationResult<PermanentMissionProgressMutation>;
export type PermanentMissionProgressMutationOptions =
  Apollo.BaseMutationOptions<
    PermanentMissionProgressMutation,
    PermanentMissionProgressMutationVariables
  >;
