import Footer from '@/components/Layout/Footer/Footer';
import LiveDropFeed from '@/components/Layout/LiveDropFeed/LiveDropFeed';
import { Header } from '@/components/Layout/SideBar/Header';
import { useAuthContext } from '@/hooks/use-auth-context';
import { useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './MainLayout.module.scss';
import { captureException } from '@sentry/nextjs';
import { mapPmMethodsToCards } from '@/utils/pm-items-helper';
import { pmAtom } from '../atoms/pm-atom';
import MainPageCards from '@/components/MainPage/MainPageCards/MainPageCards';
import { getCookie } from '@/utils/get-cookie';
import { useActivePaymentMethodsQuery } from '@/generated/payment.generated';
import useJivoSetUserData from '@/hooks/use-jivo-set-user-data';
import FirstDepPopup from '@/components/FirstDepPopup/FirstDepPopup';

interface MainLayoutProps {
  children: ReactElement;
}

const DynamicPayErrorModal = dynamic(
  () => import('@/components/Pay/PayStatusModal/PayErrorModal/PayErrorModal'),
  {
    ssr: false,
  }
);

const DynamicPaySuccessModal = dynamic(
  () =>
    import('@/components/Pay/PayStatusModal/PaySuccessModal/PaySuccessModal'),
  {
    ssr: false,
  }
);

const DynamicOldPaySuccessModal = dynamic(
  () => import('@/components/Pay/PaySuccessModal/PaySuccessModal'),
  {
    ssr: false,
  }
);

const DynamicPayModal = dynamic(
  () => import('@/components/Pay/PayModal/PayModal'),
  {
    ssr: false,
  }
);

const ModalWritePromo = dynamic(
  () =>
    import(
      '@/components/Authorized/PromoBlock/ModalWritePromo/ModalWritePromo'
    ),
  {
    ssr: false,
  }
);

const DevAuthButton = dynamic(
  () => import('@/components/Layout/DevAuthButton/DevAuthButton'),
  {
    ssr: false,
  }
);

const DynamicTermsModal = dynamic(
  () => import('@/components/TermsPopup/TermsPopup'),
  {
    ssr: false,
  }
);

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const router = useRouter();
  const { isAuthorized, userId } = useAuthContext();

  const [openPayModal, setOpenPayModal] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(true);
  const [openModalPromo, setOpenModalPromo] = useState(false);

  const handleOpenPay = () => {
    setOpenModalPromo(false);
    setOpenPayModal(true);
  };
  useJivoSetUserData(isAuthorized, userId);

  useEffect(() => {
    if (isAuthorized && router.query['modal']?.toString() === 'payment') {
      setOpenPayModal(true);
    }
  }, [router.query]);

  const setPaymentMethods = useSetAtom(pmAtom);

  useActivePaymentMethodsQuery({
    onCompleted({ payment_methods_view }) {
      const parsedPmData = mapPmMethodsToCards(payment_methods_view);
      setPaymentMethods(parsedPmData);
    },
    onError(error) {
      captureException(error);
    },
  });
  const handleOpenModalPromo = () => {
    setOpenModalPromo(true);
  };

  return (
    <main className={styles.mainBox}>
      {isAuthorized &&
        getCookie('terms_accepted') === 'false' &&
        router.pathname !== '/agreement' && (
          <DynamicTermsModal
            open={openTermsModal}
            setOpen={setOpenTermsModal}
          />
        )}

      {openPayModal && (
        <DynamicPayModal
          open={openPayModal}
          close={() => setOpenPayModal(false)}
        />
      )}
      <LiveDropFeed />
      <Header handleOpenModalPromo={handleOpenModalPromo} />
      <MainPageCards />
      <div className={styles.pageWrapper}>
        <section className={styles.mainPage}>
          <div className={styles.main}>{children}</div>
        </section>
        {router.query['payment'] &&
          router.query['payment'].toString() === 'error' && (
            <DynamicPayErrorModal />
          )}

        {router.query['payment'] &&
          router.query['payment'].toString() === 'success' && (
            // <DynamicPaySuccessModal
            //   depositSum={router.query['dep_sum']}
            //   bonusCoins={router.query['bonus_coins']}
            // />
            <DynamicOldPaySuccessModal />
          )}
        <ModalWritePromo
          open={openModalPromo}
          close={() => setOpenModalPromo(false)}
          openPay={handleOpenPay}
        />
        {/*<FirstDepPopup />*/}
        {/*<WelcomeBannerModal />*/}
        <DevAuthButton />
        <Footer />
      </div>
    </main>
  );
};

export default MainLayout;
