import { PageHead } from '@/components/PageHead';
import { AuthProvider } from '@/hooks/use-auth-context';
import { useNormalScrollRoutes } from '@/hooks/useNormalScrollRoutes';
import { passAmpliSessionIdToCookie } from '@/utils/ampli-session-cookie';
import { init } from '@amplitude/analytics-browser';
import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import App from 'next/app';
import Router, { useRouter } from 'next/router';
import nProgress from 'nprogress';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SoundProvider } from 'sounds';
import client from '../../apollo-client';
import MainLayout from '../layouts/MainLayout';
import '../styles/globals.css';
import { theme } from '@/styles/theme';
import { useLoadingPage } from '@/hooks/use-loading-page';
import { PageLoader } from '@/components/PageLoader/PageLoader';
import { isEmpty } from '@/utils/is-empty';
import { parseUtm, saveUtmToCookies } from '@/utils/traffic-param-handler';
import { useOnlineUserCount } from '@/hooks/use-online-user-count';
import posthog from 'posthog-js';

const PROD_KEY = '94ed3018cbfe51f4e49af1417acf8eff';
const DEV_KEY = '9f933d8323e0191d286cf94ab1d9c58d';

const enableMetric = process.env.NEXT_PUBLIC_ENABLE_METRIC === 'true';

init(enableMetric ? PROD_KEY : DEV_KEY);

const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;

if (typeof window !== 'undefined' && posthogKey) {
  posthog.init(posthogKey, {
    api_host: 'https://ph.hyper-drop.com',
    person_profiles: 'identified_only',
    autocapture: false,
    capture_pageview: false,
  });
}

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            limit={3}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            theme="dark"
          />
          <AuthProvider>
            <SoundProvider>{children}</SoundProvider>
          </AuthProvider>
        </ThemeProvider>
      </ApolloProvider>
    </StyledEngineProvider>
  );
};

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);
nProgress.configure({ easing: 'ease', speed: 500, minimum: 0.2 });

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useNormalScrollRoutes();
  useOnlineUserCount();
  passAmpliSessionIdToCookie();

  useEffect(() => {
    if (!isEmpty(router.query)) {
      const { marksData, referralData } = parseUtm(router.query);
      saveUtmToCookies(marksData.utm, marksData.tds, referralData);
    }
  }, [router.query]);

  const { loading } = useLoadingPage();

  const hideLayout = router.pathname.includes('demo');
  useEffect(() => {
    if (router.pathname.includes('/store')) {
      document.body.style.backgroundColor = '#0b0a1a';
      document.documentElement.style.backgroundColor = '#0b0a1a';
    } else {
      document.body.style.backgroundColor = '';
      document.documentElement.style.backgroundColor = '';
    }
  }, [router.pathname]);

  return (
    <>
      <PageHead />
      <Providers>
        {loading && <PageLoader />}
        {!hideLayout ? (
          <MainLayout>
            <Component {...pageProps} />
          </MainLayout>
        ) : (
          <Component {...pageProps} />
        )}
      </Providers>
    </>
  );
}

MyApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps> => {
  const ctx = await App.getInitialProps(context);

  return { ...ctx };
};

export default MyApp;
